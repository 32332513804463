@tailwind base;
@tailwind components;
@tailwind utilities;

/* Smooth scrolling */
html {
  scroll-behavior: smooth;
}

/* Dark mode transitions */
* {
  transition-property: background-color, border-color, color, fill, stroke;
  transition-duration: 200ms;
}
